.fixed-navbar-wrapper.navbar-wrapper {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #e5e5e5;
  display: flex;
  justify-content: center; /* Ortalamak için */
  padding: 10px 0;
}